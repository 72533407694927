import FileDownload from "js-file-download";
import axios from "axios";

export interface DownloadInputParams {
  communisUrl?: string;
  templateType?: string;
  pdfObject: JSON | any;
  downloadName: string;
}

export const downLoadPDF = (input: DownloadInputParams) => {
  axios
    .post("/api/pdfGenerator", JSON.stringify(input.pdfObject), {
      params: { template: input.templateType },
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    })
    .then((res: any) => {
      const pdfFile = new Blob([res.data], {
        type: "application/pdf",
      });
      if (pdfFile) {
        FileDownload(pdfFile, `${input.downloadName}.pdf`);
      }
    })
    .catch((FEerror: any) => console.log("FE error", FEerror));
};

export const downLoadCommunisPDF = (input: DownloadInputParams) => {
  axios
    .post("/api/communis", JSON.stringify(input.pdfObject), {
      params: { template: input.templateType, communisUrl: input.communisUrl },
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    })
    .then((res: any) => {
      const pdfFile = new Blob([res.data], {
        type: "application/pdf",
      });
      if (pdfFile) {
        FileDownload(pdfFile, `${input.downloadName}.pdf`);
      }
    })
    .catch((FEerror: any) => console.log("FE error", FEerror));
};
