import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
} from "@mui/material";
import { useTranslations } from "next-intl";
import React from "react";
import { SnackBar } from "@patron/ui/snack-bar";
import { useTheme } from "@patron/utils/theme";
import { CustomTextField } from "@patron/ui/input";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { isEmail } from "@patron/utils/helpers";

interface LoginFormProps {
  onLoginDetails: (val: LoginFormState) => void;
  data?: any;
}

export interface LoginFormState {
  userName: string;
  password: string;
  showPassword: boolean;
}

interface LoginFormError {
  userName: string;
  password: string;
}

export const LoginForm = React.forwardRef((props: LoginFormProps, ref) => {
  const t = useTranslations();
  const { onLoginDetails } = props;
  const theme = useTheme();
  const [values, setValues] = React.useState<LoginFormState>({
    userName: "",
    password: "",
    showPassword: false,
  });

  const [formIsSubmitted, setFormIsSubmitted] = React.useState(false);
  const [snackBar, setSnackBar] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [formErrors, setFormErrors] = React.useState<LoginFormError>({
    userName: t("feedback.missingValues"),
    password: t("feedback.missingValues"),
  });

  const handleChange =
    (prop: keyof LoginFormState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const localValue = event.target.value.trim();
      if (prop === "userName" || prop === "password") {
        if (prop === "userName") {
          if (localValue === "") {
            formErrors.userName = t("feedback.missingValues");
          } else {
            if (!isEmail(localValue)) {
              formErrors.userName = t("feedback.invalidEmail");
            } else {
              formErrors.userName = "";
            }
          }
        }
        if (prop === "password" && localValue === "") {
          formErrors.password = t("feedback.missingValues");
        } else {
          formErrors.password = "";
        }
      }
      setFormErrors({ ...formErrors });
      setValues({ ...values, [prop]: localValue });
    };

  React.useImperativeHandle(ref, () => ({
    onSubmit() {
      setFormIsSubmitted(true);
      if (Object.values(formErrors).some((item) => item.length > 0)) {
        setSnackBar(true);
        setSeverity("error");
        setSnackBarMessage(t("feedback.missingValues"));
      } else {
        setFormErrors({ password: "", userName: "" });
        onLoginDetails(values);
      }
    },
  }));

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBar(false);
  };

  const onKeyPressed = (event: React.KeyboardEvent<any>) => {
    if (event.key === "Enter") {
      // @ts-ignore
      const { value } = event.target;
      if (value === "") {
        formErrors.password = t("feedback.missingValues");
      } else {
        formErrors.password = "";
      }
      if (values.userName === "") {
        formErrors.userName = t("feedback.missingValues");
      } else {
        if (values.userName && !isEmail(values.userName)) {
          formErrors.userName = t("feedback.invalidEmail");
        } else {
          formErrors.userName = "";
        }
      }
      setFormErrors({ ...formErrors });
      setValues({
        ...values,
        password: value,
        userName: values.userName.trim(),
      });
      setFormIsSubmitted(true);
      if (Object.values(formErrors).some((item) => item.length > 0)) {
        setSnackBar(true);
        setSeverity("error");
        setSnackBarMessage(t("feedback.missingValues"));
      } else {
        setFormErrors({ password: "", userName: "" });
        onLoginDetails(values);
      }
    }
  };
  return (
    <Stack
      data-cy="loginForm"
      width="100%"
      id="LoginForm"
      spacing={2}
      padding={1}
    >
      <Stack
        width="100%"
        alignItems="center"
        spacing={2}
        padding={1}
        direction={"column"}
      >
        <FormControl sx={{ width: "70%" }} variant="outlined">
          <CustomTextField
            data-cy="userName"
            id="outlined-userName"
            label={t("labels.userName")}
            controldisabled={false}
            type="email"
            required
            value={values.userName}
            onChange={handleChange("userName")}
          />
          {formIsSubmitted && (
            <FormHelperText
              sx={{
                color: formErrors.userName ? theme.appColors.error : "",
              }}
              id="filled-userName-helper-text"
            >
              {formErrors.userName ? formErrors.userName : ""}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl sx={{ width: "70%" }} variant="outlined">
          <CustomTextField
            id="outlined-adornment-password"
            label={t("labels.password")}
            data-cy="password"
            controldisabled={false}
            required
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            onKeyDown={onKeyPressed}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={handleChange("password")}
          />
          {formIsSubmitted && (
            <FormHelperText
              sx={{
                color: formErrors.password ? theme.appColors.error : "",
              }}
              id="filled-name-helper-text"
            >
              {formErrors.password ? formErrors.password : ""}
            </FormHelperText>
          )}
        </FormControl>
      </Stack>
      <SnackBar
        openState={snackBar}
        //@ts-ignore
        severity={severity}
        message={snackBarMessage}
        //@ts-ignore
        onClose={handleClose}
      ></SnackBar>
    </Stack>
  );
});

LoginForm.displayName = "LoginForm";

export default LoginForm;
