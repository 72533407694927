import axios from "axios";
import FileDownload from "js-file-download";

export const downLoadCommunisZIP = async (input: any) => {
  try {
    axios
      .post(`/files/fileDownloadZipUrl`, JSON.stringify(input), {
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "blob",
      })
      .then((res) => {
        console.log(res);
        if (res.data) {
          FileDownload(res.data, input.zipFileName + ".zip");
        }
      });
  } catch (error) {
    console.log("err", error);
  }
};
