import React from "react";
import { Stack, Paper } from "@mui/material";
import LoginComposite from "../components/composites/LoginComposite";
import Head from "next/head";
import { getTranslations } from "@patron/utils/translations";

export const LoginPage = () => (
  <Stack
    paddingTop={10}
    alignItems="center"
    width="100%"
    id="LoginPage"
    data-cy="loginDiv"
  >
    <Head>
      <title>Novus login</title>
    </Head>
    <Paper>
      <LoginComposite />
    </Paper>
  </Stack>
);

export const getServerSideProps = async function () {
  const translationArray = ["login", "feedback", "common"];
  const messages = await getTranslations({
    translationContext: "login",
    translationNamespaces: translationArray,
    userLocale: "nl",
  });

  return {
    props: {
      messages: messages,
    },
  };
};

export default LoginPage;
