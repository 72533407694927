const supportedContentTypes = [
  { id: 1, type: "application/pdf" },
  { id: 2, type: "text/plain" },
  { id: 3, type: "text/html" },
  { id: 4, type: "image/jpeg" },
  { id: 5, type: "image/png" },
  { id: 7, type: "application/msword" },
  { id: 8, type: "text/html" },
];

export const MapContentType = function (contentType: string): number {
  const contentItemType = supportedContentTypes.find(
    (item: any) => item.type === contentType
  );
  return contentItemType && contentItemType.id ? contentItemType.id : 0;
};
