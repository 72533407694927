import axios from "axios";

export const uploadFile = async (
  formData: FormData,
  files: FileList,
  endPointUrl: string,
  parent?: any,
  folder?: string
) => {
  for (let index = 0; index < files.length; index++) {
    const element = files[index];
    formData.append("files", element);
  }

  const repsonse = await axios
    .put(endPointUrl, formData, {
      params: {
        parent: parent,
        folder: folder,
      },
      withCredentials: true,
    })
    .catch((error) => {
      console.error(error);
    });
  //@ts-ignore
  return repsonse.data;
};

export const uploadSupportFile = async (
  formData: FormData,
  files: FileList,
  endPointUrl: string,
  parent?: any,
  folder?: string
) => {
  for (let index = 0; index < files.length; index++) {
    const element = files[index];
    formData.append("files", element);
  }

  const repsonse = await axios
    .put(endPointUrl, formData, {
      params: {
        parent: parent,
        folder: folder,
      },
      withCredentials: true,
    })
    .catch((error) => {
      console.error(error);
    });
  //@ts-ignore
  return repsonse.data;
};
