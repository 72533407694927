import axios from "axios";
import FileDownload from "js-file-download";

interface DownloadInputParams {
  communisUrl: string;
  templateType?: string;
  type: string;
  dataObject: JSON | any;
  downloadName: string;
}

// export const docGenerate = (input: DownloadInputParams) => {
//   axios
//     .post(input.communisUrl, JSON.stringify(input.dataObject), {
//       params: { template: input.templateType },
//       // responseType: "arraybuffer",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/*",
//       },
//       withCredentials: true,
//     })
//     .then((res: any) => {
//       // const pdfFile = new Blob([res.data], {
//       //   type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
//       // });
//       // if (pdfFile) {
//       //   FileDownload(pdfFile, `${input.downloadName}.pdf`);
//       // }
//     })
//     .catch((FEerror: any) => console.log("FE error", FEerror));
// };

export const generateWordDoc = (input: DownloadInputParams) => {
  axios
    .post(
      "/api/communis",
      JSON.stringify({ type: input.type, ...input.dataObject }),
      {
        params: {
          template: input.templateType,
          communisUrl: input.communisUrl,
        },
        responseType: "arraybuffer",

        headers: {
          "Content-Type": "application/json",
          Accept: "application/*",
        },
        withCredentials: true,
      }
    )
    .then((res: any) => {
      const document = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      if (document) {
        FileDownload(document, `${input.downloadName}.docx`);
      }
    })
    .catch((FEerror: any) => console.log("FE error", FEerror));
};
