import React from "react";
import { Button, Stack, Link } from "@mui/material";
import { useTranslations } from "next-intl";
import { CustomTypographyComponent } from "@patron/ui/text";
import { LoginForm, LoginFormState } from "../forms/LoginForm";
import { useTheme } from "@patron/utils/theme";
import axios from "axios";

import Image from "next/image";
import Logo from "../../../../assets/NovusLogo-transparant.png";

export const LoginComposite = () => {
  const t = useTranslations();
  const loginRef = React.useRef();
  const theme = useTheme();
  const [failedLogin, setFailedLogin] = React.useState<boolean>(false);

  const handleLoginDetails = (val: LoginFormState) => {
    if (val) {
      axios
        .post(
          "/auth/login",
          { username: val.userName, password: val.password },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            withCredentials: true,
          }
        )
        .then((res: any) => {
          if (res && res.data && res.data.Status === 200) {
            setFailedLogin(false);
            window.location.href = "/";
          } else {
            setFailedLogin(true);
          }
        })
        .catch((FEerror: any) => {
          console.log("FE error", FEerror);
          setFailedLogin(true);
        });
    }
  };

  const handleLoginClicked = () => {
    if (loginRef && loginRef.current) {
      //@ts-ignore
      loginRef.current.onSubmit();
    }
  };

  return (
    <Stack
      data-cy="loginComp"
      alignItems="center"
      height="500px"
      width="500px"
      id="LoginComposite"
    >
      <Stack margin={3}>
        <Image src={Logo} alt="Novus logo" width={100} height={100} />
      </Stack>

      <Stack
        mt={2}
        spacing={2}
        justifyContent={"center"}
        alignItems="center"
        width="100%"
      >
        <CustomTypographyComponent variant="h5">
          {t("login.loginNovus")}
        </CustomTypographyComponent>
        <CustomTypographyComponent>
          {t("common.fillDetails")}
        </CustomTypographyComponent>
      </Stack>
      {failedLogin && (
        <CustomTypographyComponent
          variant="body1"
          data-cy="failedLoginText"
          color={theme.appColors.error}
        >
          {t("login.invalidCredentials")}
        </CustomTypographyComponent>
      )}
      <Stack justifyContent={"center"} alignItems="center" width="100%">
        <LoginForm onLoginDetails={handleLoginDetails} ref={loginRef} />
      </Stack>

      <Stack>
        <Stack alignItems={"center"} width="100%">
          <Button
            data-cy="loginButton"
            color="primary"
            variant="contained"
            onClick={handleLoginClicked}
            sx={{ mr: 1, width: "60%" }}
          >
            {t("login.login")}
          </Button>
        </Stack>
      </Stack>
      <Stack mt={4}>
        <Link href="/forgotpassword">
          <CustomTypographyComponent color={theme.palette.primary.main}>
            {t("login.forgotPassword")}
          </CustomTypographyComponent>
        </Link>
      </Stack>
    </Stack>
  );
};

export default LoginComposite;
