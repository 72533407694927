import { GQLMenuItem, GQLTenantFlatMenus } from "@patron/data-access";
import { NextRouter } from "next/router";

export const createMenuRoute = function (
  router: NextRouter,
  menuItem: GQLMenuItem
): { url: string; clientRoute: boolean } {
  let url;
  let clientRoute = false;

  if (router.basePath && router.basePath === menuItem.basePath) {
    url = menuItem.route;
    clientRoute = true;
    //@ts-ignore
    return { url, clientRoute };
  }
  //@ts-ignore
  url = menuItem.basePath + menuItem.route;
  return { url, clientRoute };
};

export interface MenuItem {
  id: string;
  title: string;
  icon: string;
  basePath: string;
  route: string;
  menuChildItem: MenuItem[];
}

export const createMenuTreeRoute = function (
  basePath: string,
  menuItem: {
    basePath: string;
    route: string;
  }
): { url: string; clientRoute: boolean } {
  let url;
  let clientRoute = false;
  let pageRouterPage = false;
  if (basePath === undefined) {
    basePath = "";
    pageRouterPage = true;
  }

  if (
    basePath === menuItem.basePath ||
    (pageRouterPage && menuItem.basePath === "page")
  ) {
    url = menuItem.route;
    clientRoute = true;
    //@ts-ignore
    return { url, clientRoute };
  }

  if (menuItem.basePath === "page") {
    menuItem.basePath = "";
  }
  url = menuItem.basePath + menuItem.route;
  return { url, clientRoute };
};

export const createMenuDHSRoute = function (
  basePath: string,
  menuItem: GQLMenuItem
): { url: string; clientRoute: boolean } {
  let url;
  let clientRoute = false;
  if (
    (basePath === undefined && menuItem.basePath === "") ||
    (basePath && basePath === menuItem.basePath)
  ) {
    url = menuItem.route;
    clientRoute = true;
    //@ts-ignore
    return { url, clientRoute };
  }
  //@ts-ignore
  url = menuItem.basePath + menuItem.route;
  return { url, clientRoute };
};
